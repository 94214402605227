import React from 'react';
import NewsItem from './NewsItem';
import EventItem from './EventItem';

import event1 from '../assets/images/event1.jpg';
import event2 from '../assets/images/event2.jpg';
import image1 from '../assets/images/image1.jpg';
import image2 from '../assets/images/image2.jpg';

function MainContent() {
    const news = [
        {
            title: 'Strona w trakcie przebudowy',
            date: '2024-03-15',
            description: 'Nasza strona jest obecnie w trakcie przebudowy.',
            link: '/aktualnosci.strona-w-trakcie-przebudowy',
            image: image1
        },
        {
            title: 'Morski Obóz Szkoleniowy Elba 2024',
            date: '2024-03-12',
            description: 'Rozpoczynamy zapisy na Morski Obóz Szkoleniowy Elba 2024!',
            link: '/aktualnosci.morski-oboz-szkoleniowy-elba-2024---rozpoczynamy-zapisy',
            image: image2
        },
        // ... więcej newsów
    ];

    const events = [
        {
            title: 'Spotkanie klubowe',
            date: '2024-04-20',
            description: 'Zapraszamy na spotkanie klubowe, które odbędzie się w naszym centrum.',
            link: '/wydarzenia.spotkanie-klubowe',
            image: event1
        },
        {
            title: 'Warsztaty nurkowe',
            date: '2024-05-15',
            description: 'Organizujemy warsztaty nurkowe dla zaawansowanych nurków.',
            link: '/wydarzenia.warsztaty-nurkowe',
            image: event2
        },
        // ... więcej wydarzeń
    ];

    return (
        <div className="container">
            <div className="left-column">
                <h3>Najważniejsze Wydarzenia</h3>
                {events.map((item, index) => (
                    <EventItem key={index} {...item} />
                ))}
            </div>
            <div className="right-column">
                <h3>Aktualności</h3>
                {news.map((item, index) => (
                    <NewsItem key={index} {...item} />
                ))}
            </div>
        </div>
    );
}

export default MainContent;
