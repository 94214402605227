import React from 'react';

function NewsItem({ title, date, description, link, image }) {
    return (
        <div className="news-item">
            <h4>{title}</h4>
            <p>{date}</p>
            {image && <img src={image} alt={title} />}
            <p>{description}</p>
            <a href={link} className="news-link">Więcej &raquo;</a>
        </div>
    );
}

export default NewsItem;
