import React from 'react';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import MainContent from './components/MainContent';
import SimpleSlider from './components/Slider';

function App() {
  return (
    <div>
      <Header />
      <NavBar />
      <SimpleSlider />
      <div className="main-container">
        <MainContent />
      </div>
      <Footer />
    </div>
  );
}

export default App;
