import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import slider1 from '../assets/images/slider1.jpg';
import slider2 from '../assets/images/slider2.jpg';
import slider3 from '../assets/images/slider3.jpg';

function SimpleSlider() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="slider">
            <Slider {...settings}>
                <div>
                    <img src={slider1} alt="Slider 1" />
                </div>
                <div>
                    <img src={slider2} alt="Slider 2" />
                </div>
                <div>
                    <img src={slider3} alt="Slider 3" />
                </div>
            </Slider>
        </div>
    );
}

export default SimpleSlider;
