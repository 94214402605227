import React from 'react';

function Footer() {
    return (
        <footer>
            <p>&copy; 2024 KRAB AGH</p>
            <p>Kontakt: kontakt@krab.agh.edu.pl</p>
        </footer>
    );
}

export default Footer;
