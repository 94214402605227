import React from 'react';

function Header() {
    return (
        <header>
            <h1>KRAB Nurkowanie</h1>
            <h2>Kursy nurkowania, Kursy Freedivingu, Szkolenia nurkowe</h2>
        </header>
    );
}

export default Header;
