import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

function NavBar() {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="/">KRAB</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link href="/">O nas</Nav.Link>
                    <NavDropdown title="Kursy" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/kursy/nurkowanie">Nurkowanie</NavDropdown.Item>
                        <NavDropdown.Item href="/kursy/freediving">Freediving</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Szkolenia" id="basic-nav-dropdown">
                        <NavDropdown.Item href="/szkolenia/zaawansowane">Zaawansowane</NavDropdown.Item>
                        <NavDropdown.Item href="/szkolenia/specjalistyczne">Specjalistyczne</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/kontakt">Kontakt</Nav.Link>
                    <Nav.Link href="/rezerwacja">Rezerwacja sprzętu</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;
